import React from "react";

function Footer() {
  return (
    <footer className="bg-[#138784] text-white py-8 pb-12">
      <div className="container mx-auto md:px-4 grid grid-cols-1 md:grid-cols-4 gap-8">
        
        {/* Logo and Company Info - spans 2 columns on medium and larger screens */}
        <div className="md:col-span-2 lg:pl-16">
          <h2 className="text-2xl md:text-4xl md:pb-1 font-coustard">RetailWise</h2>
          <p className="text-md md:text-xl font-space-grotesk">2024 PureEdge Tech Private Limited</p>
        </div>

        {/* About Company Links */}
        <div className="text-left md:text-left md:pl-4">
          <ul className="space-y-1 text-sm md:text-lg font-alexandria">
            <li><a href="#about" className="hover:underline whitespace-nowrap">About Us</a></li>
            <li><a href="#services" className="hover:underline whitespace-nowrap">Our Services</a></li>
            <li><a href="#privacy" className="hover:underline whitespace-nowrap">Privacy Policy</a></li>
          </ul>
        </div>

        {/* Social Media Links */}
        <div className="text-left md:text-left md:pl-4">
          <h3 className="text-sm md:text-lg font-alexandria tracking-wide mb-2">CONNECT WITH US</h3>
          <hr className="border-t border-white opacity-50 w-16 mb-2" />
          
          <div className="flex justify-start md:justify-start space-x-4">
            <a href="#twitter" aria-label="Twitter" className="hover:opacity-80">
              <img src="https://res.cloudinary.com/delwjbj2a/image/upload/v1731489395/twitter-icon_k3fmcj.svg" alt="Twitter" className="w-6 h-6 md:w-8" />
            </a>
            <a href="#facebook" aria-label="Facebook" className="hover:opacity-80">
              <img src="https://res.cloudinary.com/delwjbj2a/image/upload/v1731489394/facebook-icon_wkuhg0.svg" alt="Facebook" className="w-6 h-6 md:w-8" />
            </a>
            <a href="#instagram" aria-label="Instagram" className="hover:opacity-80">
              <img src="https://res.cloudinary.com/delwjbj2a/image/upload/v1731489394/instagram-icon_jzfiau.svg" alt="Instagram" className="w-6 h-6 md:w-8" />
            </a>
            <a href="#linkedin" aria-label="LinkedIn" className="hover:opacity-80">
              <img src="https://res.cloudinary.com/delwjbj2a/image/upload/v1731489394/linkedIn-icon_yyiogo.svg" alt="LinkedIn" className="w-6 h-6 md:w-8" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
