import React, { useState } from "react";

function Navbar() {
  const [active, setActive] = useState("#brands");

  const getLinkClasses = (href) => {
    const baseClasses = "pb-1 font-header-font text-[16px] transition duration-300";
    if (href === active) {
      return `${baseClasses} text-primary inline-block border-b-4 border-primary rounded-t-xl`;
    } else {
      return `${baseClasses} text-header-text`;
    }
  };

  return (
    <nav className="shadow-md pb-0 pt-4 sm:py-4 border-b bg-[#F6F5F5]">
      <div className="container w-full sm:flex sm:flex-row items-center justify-between sm:px-8 mx-auto">

        {/* Logo */}
        <div className="flex items-center space-x-2">
          <img
            src="https://res.cloudinary.com/delwjbj2a/image/upload/v1731488456/logo_py7tn3.png"
            alt="Retailwise Logo"
            className="h-10"
          />
        </div>

        {/* Navigation Links for Desktop */}
        <div className="hidden sm:flex sm:items-center space-x-8">
          <a
            href="#brands"
            onClick={() => setActive("#brands")}
            className="text-[#474848] hover:text-teal-600 text-xl font-medium"
          >
            Brands
          </a>
          <a
            href="#eventcalendar"
            onClick={() => setActive("#eventcalendar")}
            className="text-[#474848] hover:text-teal-600 text-xl font-medium"
          >
            Event Calendar
          </a>
          <a
            href="#blogs"
            onClick={() => setActive("#blogs")}
            className="text-[#474848] hover:text-teal-600 text-xl font-medium"
          >
            Blogs
          </a>
        </div>

        {/* Navigation Links for Mobile */}
        <div className="sm:hidden mt-4 px-2 py-2 pb-0">
          <nav className="flex justify-around w-full">
            <a
              href="#brands"
              onClick={() => setActive("#brands")}
              className={getLinkClasses("#brands")}
            >
              Brands
            </a>
            <a
              href="#eventcalendar"
              onClick={() => setActive("#eventcalendar")}
              className={getLinkClasses("#eventcalendar")}
            >
              Event Calendar
            </a>
            <a
              href="#blogs"
              onClick={() => setActive("#blogs")}
              className={getLinkClasses("#blogs")}
            >
              Blogs
            </a>
          </nav>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
