import React, { useState } from "react";

function HowItWorksSection() {
  const steps = [
    {
      number: 1,
      title: "Introduce brands",
      description:
        "Plan customer offers or in-store promoters or product sampling",
      imageUrl: "https://res.cloudinary.com/delwjbj2a/image/upload/v1731488531/default-image_hmmlr0.png",
    },
    {
      number: 2,
      title: "Select the stores",
      description:
        "Plan customer offers or in-store promoters or product sampling",
      imageUrl: "https://res.cloudinary.com/delwjbj2a/image/upload/v1731488531/default-image_hmmlr0.png",
    },
    {
      number: 3,
      title: "Marketing campaign",
      description:
        "Plan customer offers or in-store promoters or product sampling",
      imageUrl: "https://res.cloudinary.com/delwjbj2a/image/upload/v1731488531/default-image_hmmlr0.png",
    },
    {
      number: 4,
      title: "Secure Payment",
      description:
        "Plan customer offers or in-store promoters or product sampling",
      imageUrl: "https://res.cloudinary.com/delwjbj2a/image/upload/v1731488531/default-image_hmmlr0.png",
    },
    {
      number: 5,
      title: "Ship the goods",
      description:
        "Plan customer offers or in-store promoters or product sampling",
      imageUrl: "https://res.cloudinary.com/delwjbj2a/image/upload/v1731488531/default-image_hmmlr0.png",
    },
    {
      number: 6,
      title: "Brand dashboard",
      description:
        "Plan customer offers or in-store promoters or product sampling",
      imageUrl: "https://res.cloudinary.com/delwjbj2a/image/upload/v1731488531/default-image_hmmlr0.png",
    },
    {
      number: 7,
      title: "Distribution",
      description:
        "Plan customer offers or in-store promoters or product sampling",
      imageUrl: "https://res.cloudinary.com/delwjbj2a/image/upload/v1731488531/default-image_hmmlr0.png",
    },
  ];

  const [activeStep, setActiveStep] = useState(1);

  return (
    <section className="py-8 md:py-16">
      <div className="container mx-auto md:px-4">
        <h2 className="text-2xl md:text-5xl font-medium text-gray-800 md:pb-4 mb-4 md:mb-8 text-left font-coustard border-b">
          How It <span className="text-[#138784]">Works</span>
        </h2>

        <div className="flex flex-col lg:flex-row items-center justify-center gap-6 md:gap-8">
          <div className="w-full lg:w-1/3">
            <ul className="grid grid-cols-2 gap-2 overflow-x-auto md:overflow-visible lg:flex lg:flex-col">
              {steps.map((step) => (
                <li
                  key={step.number}
                  className={`flex items-center p-1 pl-0 md:p-3 rounded-lg cursor-pointer ${
                    activeStep === step.number
                      ? "bg-[#138784] text-white font-bold"
                      : "bg-[#F1F1F1] text-[#2E2E2E] font-normal"
                  }`}
                  onClick={() => setActiveStep(step.number)}
                >
                  <span
                    className={`flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-md font-semibold mr-0 md:mr-4 ${
                      activeStep === step.number
                        ? "text-white"
                        : "bg-[#F1F1F1] text-[#2E2E2E]"
                    }`}
                  >
                    {step.number}
                  </span>
                  <span className="text-sm md:text-lg">{step.title}</span>
                </li>
              ))}
            </ul>
          </div>

          <div className="w-full lg:w-2/3 flex justify-center items-center">
            <div className="relative bg-white overflow-hidden w-full xl:h-[450px] rounded-lg shadow-lg">
              <div className="relative z-10">
                <img
                  src={steps[activeStep - 1].imageUrl}
                  alt="Step Illustration"
                  className="w-full h-auto object-con rounded-xl"
                />
                <div className="absolute inset-0 bg-black opacity-40 rounded-lg"></div>
              </div>

              {/* Centered Text */}
              <div className="absolute inset-0 flex flex-col justify-center items-start text-center rounded-lg text-white p-4 md:p-8 z-20">
                <h3 className="text-2xl md:text-4xl max-w-6xl font-semibold mb-2 border-b-2">
                  {activeStep}. {steps[activeStep - 1].title}
                </h3>
                <p className="text-base md:text-lg">
                  {steps[activeStep - 1].description}
                </p>
              </div>

              {/* Play Button */}
              <div className="absolute top-4 left-4 z-20">
                <button className="text-white rounded-full p-2">
                  <svg
                    className="hidden md:block"
                    width="61"
                    height="61"
                    viewBox="0 0 61 61"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="61" height="61" rx="30.5" fill="white" />
                    <rect width="61" height="61" rx="30.5" stroke="black" />
                    <circle cx="30.5" cy="30.5" r="22.5" fill="#138784" />
                    <path
                      d="M25 40.0228V21.8463C25 20.2273 26.8233 19.2792 28.1487 20.2091L40.7324 29.0377C41.8488 29.8209 41.8711 31.4672 40.7764 32.2804L28.1926 41.6283C26.873 42.6086 25 41.6667 25 40.0228Z"
                      fill="white"
                    />
                  </svg>
                  <svg
                    className="block md:hidden"
                    width="31"
                    height="35"
                    viewBox="0 0 31 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="31" height="35" rx="15.5" fill="white" />
                    <rect width="31" height="35" rx="15.5" stroke="black" />
                    <circle cx="15.5" cy="17.5" r="11.5" fill="#138784" />
                    <path
                      d="M12 18.0228V12.8463C12 11.2273 13.8233 10.2792 15.1487 11.2091L18.7324 13.7234C19.8488 14.5066 19.8711 16.1529 18.7764 16.9661L15.1926 19.6283C13.873 20.6086 12 19.6667 12 18.0228Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>

              {/* Navigation Arrows */}
              <div className="absolute bottom-4 left-4 md:left-8 z-20">
                <button
                  onClick={() =>
                    setActiveStep((prev) =>
                      prev > 1 ? prev - 1 : steps.length
                    )
                  }
                  className="p-2 transition-all duration-300 text-white"
                >
                  {/* Left Arrow - Mobile */}
                  <svg
                    className="block md:hidden"
                    width="23"
                    height="25"
                    viewBox="0 0 23 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.85886 15.0331L11.454 18.5832C13.4513 19.8505 16.0612 18.4156 16.0612 16.0501L16.0612 8.94991C16.0612 6.58443 13.4513 5.14947 11.454 6.41679L5.85886 9.96688C4.00187 11.1451 4.00187 13.8549 5.85886 15.0331Z"
                      stroke="#F3F3F3"
                      stroke-width="2"
                    />
                    <path
                      d="M1.17383 21.7041L1.17383 2.1705"
                      stroke="#F3F3F3"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>

                  {/* Left Arrow - Desktop */}
                  <svg
                    className="hidden md:block"
                    width="47"
                    height="58"
                    viewBox="0 0 47 58"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.56466 27.4506C5.47808 28.2498 5.47808 29.8735 6.56467 30.6728L29.8209 47.7792C31.1416 48.7506 33.006 47.8076 33.006 46.1681L33.006 11.9553C33.006 10.3157 31.1416 9.37269 29.8209 10.3442L6.56466 27.4506Z"
                      stroke="#F3F3F3"
                      strokeWidth="4"
                    />
                    <path
                      d="M3.3418 50.3291L3.34179 5.19254"
                      stroke="#F3F3F3"
                      strokeWidth="5"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              </div>
              <div className="absolute bottom-4 right-4 md:right-8 z-20">
                <button
                  onClick={() =>
                    setActiveStep((prev) =>
                      prev < steps.length ? prev + 1 : 1
                    )
                  }
                  className="p-2 transition-all duration-300 text-white"
                >
                  {/* Right Arrow - Mobile */}
                  <svg
                    className="block md:hidden"
                    width="23"
                    height="25"
                    viewBox="0 0 23 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.8892 15.0331L11.2941 18.5832C9.29673 19.8505 6.68681 18.4156 6.68681 16.0501L6.68681 8.94991C6.68681 6.58443 9.29673 5.14947 11.2941 6.41679L16.8892 9.96688C18.7462 11.1451 18.7462 13.8549 16.8892 15.0331Z"
                      stroke="#F3F3F3"
                      stroke-width="2"
                    />
                    <path
                      d="M21.5742 21.7041L21.5742 2.1705"
                      stroke="#F3F3F3"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>

                  {/* Right Arrow - Desktop */}
                  <svg
                    className="hidden md:block"
                    width="46"
                    height="58"
                    viewBox="0 0 46 58"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M39.7762 27.5053C40.8627 28.3045 40.8627 29.9282 39.7762 30.7274L16.5199 47.8338C15.1992 48.8053 13.3349 47.8623 13.3349 46.2227L13.3349 12.0099C13.3349 10.3704 15.1992 9.42738 16.5199 10.3989L39.7762 27.5053Z"
                      stroke="#F3F3F3"
                      strokeWidth="4"
                    />
                    <path
                      d="M43 50.3838L43 5.24722"
                      stroke="#F3F3F3"
                      strokeWidth="5"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowItWorksSection;
