import React, { useState, useEffect } from "react";
import Modal from "./CTAModal"; // Import the Modal component created below

function HeroSection() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden"; // Disable scroll when modal is open
    } else {
      document.body.style.overflow = "auto"; // Enable scroll when modal is closed
    }
    return () => (document.body.style.overflow = "auto"); // Clean up on unmount
  }, [isModalOpen]);

  return (
    <section className="relative flex justify-center md:p-4 md:mt-8">
      <div className="md:container md:mx-auto">
        {/* Desktop Image */}
        <img
          src="https://res.cloudinary.com/delwjbj2a/image/upload/f_auto,q_auto,w_1200/v1731488386/desktop-image_awljw7.png"
          alt="Hero"
          className="hidden md:block w-full h-auto"
        />

        {/* Mobile Image */}
        <img
          src="https://res.cloudinary.com/delwjbj2a/image/upload/f_auto,q_auto,w_600/v1731488385/mobile-image_l2qj5v.png"
          alt="Hero Mobile"
          className="block md:hidden w-full h-auto"
        />

        {/* Content */}
        <div
          className="absolute inset-0 flex items-end justify-center z-10"
          style={{ position: "relative" }}
        >
          {/* Call to Action Button for Desktop */}
          <button
            onClick={() => setIsModalOpen(true)}
            className="hidden md:flex bg-[#138784] hover:bg-teal-600 text-white rounded-full font-semibold items-center 
                       py-2 px-8 text-lg lg:px-10 lg:py-3 lg:text-xl transition duration-200 ease-in-out"
            style={{ bottom: "4rem", position: "absolute" }}
          >
            Get Started
            <svg
              width="56"
              height="24"
              viewBox="0 0 56 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ml-2"
            >
              <path
                d="M2 10.5C1.17157 10.5 0.5 11.1716 0.5 12C0.5 12.8284 1.17157 13.5 2 13.5L2 10.5ZM55.0607 13.0607C55.6464 12.4749 55.6464 11.5251 55.0607 10.9393L45.5147 1.3934C44.9289 0.807614 43.9792 0.807614 43.3934 1.3934C42.8076 1.97919 42.8076 2.92893 43.3934 3.51472L51.8787 12L43.3934 20.4853C42.8076 21.0711 42.8076 22.0208 43.3934 22.6066C43.9792 23.1924 44.9289 23.1924 45.5147 22.6066L55.0607 13.0607ZM2 13.5L54 13.5L54 10.5L2 10.5L2 13.5Z"
                fill="white"
              />
            </svg>
          </button>

          {/* Call to Action Button for Mobile */}
          <button
            onClick={() => setIsModalOpen(true)}
            className="flex md:hidden bg-[#138784] hover:bg-teal-600 text-white rounded-full font-semibold items-center 
                       py-1 px-4 text-base transition duration-200 ease-in-out"
            style={{ position: "absolute", bottom: "80px" }}
          >
            Get Started
            <svg
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ml-1"
            >
              <path
                d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75V5.25ZM15.5303 6.53033C15.8232 6.23744 15.8232 5.76256 15.5303 5.46967L10.7574 0.696699C10.4645 0.403806 9.98959 0.403806 9.6967 0.696699C9.40381 0.989593 9.40381 1.46447 9.6967 1.75736L13.9393 6L9.6967 10.2426C9.40381 10.5355 9.40381 11.0104 9.6967 11.3033C9.98959 11.5962 10.4645 11.5962 10.7574 11.3033L15.5303 6.53033ZM1 6.75H15V5.25H1V6.75Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} />}
    </section>
  );
}

export default HeroSection;
