import React from "react";
import HeroSection from "../components/HeroSection";
import WhyRetailWiseSection from "../components/WhyRetailwiseSection";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import CaseStudiesSection from "../components/CaseStudiesSection";
import HowItWorksSection from "../components/HowItWorksSection";

function HomePage() {
  return (
    <div className="mx-auto bg-white z-10">
      <Navbar />
      <HeroSection />
      <CaseStudiesSection />
      <HowItWorksSection />
      <WhyRetailWiseSection />
      <Footer />
    </div>
  );
}

export default HomePage;
